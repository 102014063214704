
import https from './https.js';
let baseUrl = '/api'
//获取权限角色列表
export function listParams(params) {
    return https({
		url: baseUrl+'/web-api-data/list-params',
		method: 'post',
		data: params
	})
}

//getBannerForWeb

export function  getBannerForWeb(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-index/getBannerForWeb', // 请求url
		// params: params,// 参数
		data: params
	})
}



export function  getCityList(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-index/getCityList', // 请求url
		// params: params,// 参数
		data: params
	})
}

//新房、二手房、商铺出租 列表
export function  listHouse(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/listHouse', // 请求url
		// params: params,// 参数
		data: params
	})
}
//商铺楼盘列表 /web-api/listBuilding
export function  listBuilding(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/listBuilding', // 请求url
		// params: params,// 参数
		data: params
	})
}


export function  getCompanyDesc(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-index/getCompanyDesc', // 请求url
		// params: params,// 参数
		data: params
	})
}

export function  selectListNewsForWeb(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-index/selectListNewsForWeb', // 请求url
		// params: params,// 参数
		data: params
	})
}

export function  detailHouse(url) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+url, // 请求url
	})
}

export function  getNewsByGuid(url) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+url, // 请求url
	})
}

export function  selectAdvisorListForWeb(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-advisor/selectAdvisorListForWeb', // 请求url
		// params: params,// 参数
		data: params
	})
}
export function  getAdvisorByUid(url) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+url, // 请求url
	})
}



export function  listMapBuilding(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/listMapBuilding', // 请求url
		// params: params,// 参数
		data: params
	})
}


//预约看房
// async saveApplication(params) {
//     const res = await https({
//         method: "post", // 请求方式
//         url: baseUrl+'/web-api/saveApplication', // 请求url
//         // params: params,// 参数
//         data: params
//     })
//     return res
// }

export function  saveApplication(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/saveApplication', // 请求url
		// params: params,// 参数
		data: params
	})
}


export function  listBuildingComment(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/listBuildingComment', // 请求url
		// params: params,// 参数
		data: params
	})
}

export function  listBuildingAsk(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/listBuildingAsk', // 请求url
		// params: params,// 参数
		data: params
	})
}

export function  saveBuildingAsk(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/saveBuildingAsk', // 请求url
		// params: params,// 参数
		data: params
	})
}
export function  saveBuildingComment(params) {
	return https({
		method: "post", // 请求方式
		url: baseUrl+'/web-api/saveBuildingComment', // 请求url
		// params: params,// 参数
		data: params
	})
}


export function  getCity() {
	return https({
		method: "get", // 请求方式
        url: baseUrl+'/web-api/getCityCode', // 请求url
	})
}