/*
 * @Author: your name
 * @Date: 2021-07-05 08:52:02
 * @LastEditTime: 2021-11-23 14:20:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \code\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import App from '@/App'
Vue.use(VueRouter);
// import App from '@/App'
const routes = [

    {path:'/',redirect:'/index'},
    {
        path:'/index',
        name: 'index',
        component: () => import("@/views/index/index"),
        meta: {
            title: "首页" ,
            keepAlive: true,
            isNeedRefresh: true
        }
    },
    {
        path: "/center",
        name: 'center',
        component: () => import("@/views/center/index"),
        meta: { 
            title: "营信地产" ,
            // keepAlive: true
        }
    },
    {
        path: "/news",
        name: 'news',
        component: () => import("@/views/news/index"),
        meta: { 
            title: "资讯",
            keepAlive: true,
            isNeedRefresh: true
         }
    },
    {
        path: "/counselor",
        name: 'counselor',
        component: () => import("@/views/counselor/index"),
        meta: { 
            title: "顾问",
            keepAlive: true,
            isNeedRefresh: true
         }
    },
    {
        path: "/map",
        name: 'map',
        component: () => import("@/views/map/index"),
        meta: { 
            title: "地图找房" ,
            // keepAlive: true
        }
    },
    {
        path: "/subpackages/newsDetails",
        name: 'newsDetails',
        component: () => import("@/views/subpackages/newsDetails/index"),
        meta: { 
            title: "资讯详情",
            // keepAlive: true
         }
    },
    {
        path: "/subpackages/counselorDetails",
        name: 'counselorDetails',
        component: () => import("@/views/subpackages/counselorDetails/index"),
        meta: { 
            title: "顾问详情" ,
            // keepAlive: true
        }
    },
    {
        path: "/subpackages/rentalHousingDetails",
        name: 'rentalHousingDetails',
        component: () => import("@/views/subpackages/rentalHousingDetails/index"),
        meta: { 
            title: "",
            // keepAlive: true
         }
    },
    {
        path: "/subpackages/bookRoom",
        name: 'bookRoom',
        component: () => import("@/views/subpackages/bookRoom/index"),
        meta: { 
            title: "预约看房" ,
            // keepAlive: true
        }
    },
    {
        path: "/subpackages/propertyBookRoom",
        name: 'propertyBookRoom',
        component: () => import("@/views/subpackages/propertyBookRoom/index"),
        meta: { 
            title: "预约看房",
            // keepAlive: true
        }
    },
    {
        path: "/subpackages/askRoom",
        name: 'askRoom',
        component: () => import("@/views/subpackages/askRoom/index"),
        meta: { 
            // keepAlive: true,
            title: "问房" 
        }
    },
    {
        path: "/subpackages/propertyDetails",
        name: 'propertyDetails',
        component: () => import("@/views/subpackages/propertyDetails/index"),
        meta: { 
            // keepAlive: true,
            title: "商铺楼盘详情" 
        }
    },
    // {
    //     path: "/subpackages/askRoom",
    //     name: 'askRoom',
    //     component: () => import("@/views/subpackages/askRoom/index"),
    //     meta: { 
    //         // keepAlive: true,
    //         title: "预约看房" 
    //     }
    // },
    {
        path: "/subpackages/comment",
        name: 'comment',
        component: () => import("@/views/subpackages/comment/index"),
        meta: { title: "点评" }
    },
    {
        path: "/subpackages/newsList",
        name: 'newsList',
        component: () => import("@/views/subpackages/newsList/index"),
        meta: { title: "资讯" }
    },
    {
        path: "/subpackages/commentList",
        name: 'commentList',
        component: () => import("@/views/subpackages/commentList/index"),
        meta: { title: "点评列表" }
    },
    {
        path: "/subpackages/askRoomList",
        name: 'askRoomList',
        component: () => import("@/views/subpackages/askRoomList/index"),
        meta: { title: "问房列表" }
    },
    {
        path: "/subpackages/roomList",
        name: 'roomList',
        component: () => import("@/views/subpackages/roomList/index"),
        meta: { 
            // keepAlive: true,
            title: "房源列表" 
        }
    }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
      return savedPosition
    } else {
      return {x: 0, y: 0}//savedPosition也是一个记录x轴和y轴位置的对象
    }
  },
});

// 全局前置守卫
// router.beforeEach((to, from, next) => {
//     console.log(to)
//     console.log(from)
//     if (to.name !== 'index') {
//         console.log(1)
//       // 如果不是从首页离开，则滚动到顶部
//       window.scrollTo(0, 0);
//     }
//     next();
//   });
export default router;
